<template>
  <div>
    <PickupFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      tooltip-title="ALL_REQ_SUPPORT"
      title="All Requests"
      feature="Student Dispatcher"
      :show-status-filter="true"
      @apply="applyFilters"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(requestsList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.slice(0, -1).filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, ['Name', 'Pickup Person'])
            "
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(request, index) in requestsList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <span @click="redirectToStudentMode(request.student, 'support-all-request')">
              <SingleUserDisplay
                :user="request.student"
                label="full_name"
                :image="true"
                :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
                :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
              />
            </span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Last Activity At')"
            @click="$router.push({ name: 'request-details', params: { id: request.id } })"
          >
            <span v-if="request.pickup_request_logs" class="text-primary-green">
              {{ $filters.dateFormat(request.pickup_request_logs[0].inserted_at) }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Pickup Person', ['Pickup Person'])">
            <SingleUserDisplay
              :user="request.pickup_person"
              :required-items="1"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Main Guardian')">
            <SingleUserDisplay
              :user="request?.student?.student_info?.main_guardian"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, requestsList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, requestsList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <div
              :style="pickupRequestStyle(request.status)"
              class="capitalize"
              @click="$router.push({ name: 'request-details', params: { id: request.id } })"
            >
              {{ request.status || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </div>
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(requestsCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
  </div>
</template>

<script>
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import isEmpty from 'lodash/isEmpty'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import fileMixins from '@/src/mixins/file-mixins'
import PickupFilter from '@src/router/views/pickup/pickup-support/PickupRequestFilter.vue'
import PickupRequestData from '@src/router/views/pickup/pickup-support/pickup-request.json'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import { pickupRequestStyle, redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import { dropdownTopBottom, objectDeepCopy, showColumnInDataTable } from '@src/utils/generalUtil.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  AllRequestsGraphChart,
  rightBarStats,
} from '@/src/router/views/pickup/pickup-support/AllRequests.ulit.js'
import AllRequstsData from '@/src/router/views/pickup/pickup-support/AllRequests.json'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    Pagination,
    Loader,
    PickupFilter,
    SingleUserDisplay,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      TENANT_ROLES,
      isEmpty,
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      isLoading: false,
      tableHeaders: PickupRequestData.tableHeaders,
      defaultSelectedColumns: PickupRequestData.defaultSelectedColumns,
      currentSelectedRequest: {},
      filteredRecordLimit: 10,
      filtersData: {},
      confirmation: false,
      showPagination: false,
      requestsList: [],
      showRequestModal: false,
      requestsCounts: 0,
    }
  },

  computed: {
    showBreadcrumb() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  watch: {
    currentCampusScope: {
      immediate: true,
      handler() {
        this.filterRecords()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecords()
      },
    },
  },

  created() {
    this.setRightbar()
    this.tableHeaders[5].skipForFilterOptions = false
    this.defaultSelectedColumns.splice(0, 1, 'Last Activity At')
    this.tableHeaders[1].dataTableName = 'Last Activity At'
    this.tableHeaders[1].backendKeyForCSV = 'Last Activity At'
    this.tableHeaders[1].readableValueForCSV = 'Last Activity At'
    this.filterRecords()
  },
  methods: {
    redirectToStudentMode,
    showColumnInDataTable,
    dropdownTopBottom,
    pickupRequestStyle,
    userActions(action, currentRequest, idx) {
      this.currentSelectedRequest = currentRequest
      this.currentUserPosition = idx
    },
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },

    filterRecords(range) {
      this.requestLogs = []
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getAllPickupRequests(payload)
        .then((response) => {
          this.requestsList = response.records
          this.requestsCounts = response.meta.total_records
          this.requestsList.forEach((request) => {
            fullName(request.student)
            fullName(request.pickup_person)
            if (request.student?.student_info?.main_guardian)
              fullName(request.student?.student_info?.main_guardian)
          })

          this.showPagination = this.requestsCounts > 10
          this.setRightbar(this.requestsCounts)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setRightbar(count = 0) {
      const rightBarContent = {
        header: {
          title: 'Pickup Requests',
        },
        stats: rightBarStats(),
        chartData: AllRequestsGraphChart(count),
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('pickup', ['getAllPickupRequests']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
