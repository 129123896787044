import AllRequestsData from '@/src/router/views/pickup/pickup-support/AllRequests.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = () => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
    },
  ]
  return stats
}

export const AllRequestsGraphChart = (AllRequests) => {
  let BAR_GRAPH_VALUE = [20, 15, 5, 10]

  const chartData = [
    GRAPH_CONSTANTS.BAR_CHART(
      AllRequestsData.BAR_LABELS,
      BAR_GRAPH_VALUE,
      AllRequestsData.BAR_TITLE,
      AllRequestsData.BAR_LABELS_STRING,
    ),
  ]
  return chartData
}
